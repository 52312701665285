import { FemaleAdvantagesSection } from "@/featuresPrincess/landingPage/FemaleAdvantagesSection";
import { HomeIntroSection } from "@/featuresPrincess/landingPage/HomeIntroSection";
import { MaleAdvantagesSection } from "@/featuresPrincess/landingPage/MaleAdvantagesSection";
import { PrincessFeaturesSection } from "@/featuresPrincess/landingPage/PrincessFeaturesSection";
import { ReviewSection } from "@/featuresPrincess/landingPage/ReviewSection";
import { StillThinkingSection } from "@/featuresPrincess/landingPage/StillThinkingSection";
import { Element } from "react-scroll";

export const PrincessHomePage = () => {
  return (
    <>
      <Element name="home">
        <HomeIntroSection />
      </Element>
      <Element name="features">
        <PrincessFeaturesSection />
      </Element>
      <Element name="femaleAdv">
        <FemaleAdvantagesSection />
      </Element>

      <StillThinkingSection />
      <Element name="maleAdv">
        <MaleAdvantagesSection />
      </Element>
      <Element name="review">
        <ReviewSection />
      </Element>
    </>
  );
};
