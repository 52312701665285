import { useDomainContext } from "@/helpers/useDomainContext";
import StarIcon from "public/assets/icons/star.svg";
import { useTranslation } from "next-i18next";
import Image from "next/image";

const placeholderReviews = [
  {
    avatar: "/assets/images/usershare_user1.png",
    name: "Charlotte",
    comment:
      "呢度識既男仔同我在一般交友app識既男仔好唔同！質素高好多！\n高好多既意思唔係淨係指經濟條件，而係識做人，易相處。\n我試過去其他交友app，食餐飯要AA制，找錢幾蚊都追我拎返！",
  },
  {
    avatar: "/assets/images/usershare_user2.png",
    name: "Daphne",
    comment:
      "之前我只係一個資優生，書呆子。在Princess-dating識到男朋友後，我真正被開發。\n懂得享受刺激和尋找人生樂趣，同時亦眼界大開。",
  },
  {
    avatar: "/assets/images/usershare_user3.png",
    name: "Ken",
    comment:
      "在來princess-dating之前，玩的交友app大部份都是傳銷、保險妹，然後便沒有然後。在這裡只要成為尊貴會員，一鍵便可以立即獲得女會員聯絡方法，然後第二天便約會成功。\nprincess-dating教識我誠意和大方的重要性。",
  },
];

export const ReviewSection = () => {
  const { t } = useTranslation("home");
  const { isMillionBB } = useDomainContext();
  return (
    <div className="mx-auto flex w-full max-w-screen-lg flex-col items-center gap-8 bg-gray-50 px-4 py-16">
      <h3 className="text-3xl">{t("section.review.title")}</h3>
      <div className="flex flex-col gap-4 md:flex-row">
        {placeholderReviews.map((review, i) => (
          <div
            key={i}
            className="flex flex-1 flex-col gap-6 rounded-2xl bg-white px-6 pb-8 pt-12 shadow-xl"
          >
            <div className="flex gap-1">
              {Array.from({ length: 5 }).map((_, i) => (
                <StarIcon key={i} className="h-5 w-5 text-[#FBBF24]" />
              ))}
            </div>

            <p className="mb-3 whitespace-pre-line text-justify text-base font-normal text-gray-500">
              {isMillionBB
                ? review.comment.replaceAll(/princess-dating/gi, "MillionBB")
                : review.comment}
            </p>

            <div className="mt-auto flex items-center gap-5">
              <div className="relative h-16 w-16">
                <Image src={review.avatar} fill alt={review.name} />
              </div>

              <h3 className="text-2xl font-medium text-gray-700">
                {review.name}
              </h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
