import { useDomainContext } from "@/helpers/useDomainContext";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { Carousel } from "react-responsive-carousel";

const FeatureItem = ({
  imgProps,
  title,
  description,
}: {
  imgProps: {
    src: string;
    width?: number;
    height?: number;
    fill?: boolean;
    className?: string;
  };
  title: string;
  description: string;
}) => {
  return (
    <div className="flex w-full max-w-full flex-1 flex-col items-center">
      <div className="relative h-40 w-40">
        <Image {...imgProps} alt={title} priority unoptimized />
      </div>
      <div className="mt-5 text-lg font-medium text-gray-700">{title}</div>
      <div className="mt-2 whitespace-pre-line text-center text-base font-normal text-gray-500">
        {description}
      </div>
    </div>
  );
};

const mbbFeaturesImgProps = [
  {
    src: "/assets/images/home_desc_1.png",
    width: 137.07,
    height: 117.97,
    className: "object-contain h-full w-auto",
  },
  {
    src: "/assets/images/home_desc_2.png",
    width: 108.24,
    height: 117.97,
    className: "object-contain h-full mx-auto w-auto",
  },
  {
    src: "/assets/images/home_desc_3.png",
    width: 91.37,
    height: 117.97,
    className: "object-contain h-full mx-auto w-auto",
  },
];

const princessFeaturesImgProps = [
  {
    src: "/assets/icons/princess_adv_1.svg",
    fill: true,
  },
  {
    src: "/assets/icons/princess_adv_2.svg",
    fill: true,
  },
  {
    src: "/assets/icons/princess_adv_3.svg",
    fill: true,
  },
];

export const PrincessFeaturesSection = () => {
  const { t } = useTranslation("home");
  const { isMillionBB } = useDomainContext();
  return (
    <div className="w-full bg-white">
      <div className="mx-auto flex max-w-screen-lg flex-col items-center px-4 py-9">
        <h3 className="mx-auto mb-10 text-3xl text-gray-700">
          {t(
            isMillionBB
              ? `section.features.title_millionbb`
              : `section.features.title_princess`,
          )}
        </h3>
        <div className="block w-full sm:hidden">
          <Carousel
            showThumbs={false}
            infiniteLoop
            showStatus={false}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              return (
                <li
                  className={`inline-block h-2 w-2 rounded-full ${
                    isSelected ? "bg-gray-700" : "bg-gray-400"
                  } ${index !== 0 && "ml-4"}`}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  title={`${label} ${index + 1}`}
                  aria-label={`${label} ${index + 1}`}
                />
              );
            }}
          >
            {(isMillionBB ? mbbFeaturesImgProps : princessFeaturesImgProps).map(
              (imgProps, i) => (
                <div className="pb-12" key={i}>
                  {/* Extra padding to show  */}
                  <FeatureItem
                    imgProps={imgProps}
                    title={t(`section.features.item_${i + 1}_title`)}
                    description={t(
                      `section.features.item_${i + 1}_description`,
                    )}
                  />
                </div>
              ),
            )}
          </Carousel>
        </div>
        <div className="hidden gap-8 sm:flex">
          {(isMillionBB ? mbbFeaturesImgProps : princessFeaturesImgProps).map(
            (imgProps, i) => (
              <FeatureItem
                imgProps={imgProps}
                title={t(`section.features.item_${i + 1}_title`)}
                description={t(`section.features.item_${i + 1}_description`)}
                key={i}
              />
            ),
          )}
        </div>
      </div>
    </div>
  );
};
