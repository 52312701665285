import { Route } from "@/consts";
import { PrincessHomePage } from "@/featuresPrincess/landingPage";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { UserStatusEnum } from "@princess/graphql-codegen/graphql-hooks";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { type GetStaticPropsContext } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export const HomePage = () => {
  const { user, loading } = useUserContext();
  const router = useRouter();

  useEffect(() => {
    if (!loading && user?.status === UserStatusEnum.ProfileCompleted) {
      router.push(Route.MemberHome);
    }
  }, [loading, user?.status]);

  return <PrincessHomePage />;
};

export default HomePage;

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? "zh", undefined)),
    },
  };
}
